body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .logoDark {
  display: none;
}

body.dark .logoLight {
  display: none;
}

body.dark .logoDark {
  display: initial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* .MuiPopover-paper {
  max-height: 180px !important;
} */
.MuiAccordion-root:before {
  opacity: 0 !important;
}

[data-tooltip] {
  position: relative;
  cursor: default;
}

[data-tooltip]::after {
  position: absolute;
  width: calc(100% + 16px);
  left: 0;
  bottom: 180%;
  text-align: center;
  box-sizing: border-box;
  content: attr(data-tooltip);
  padding: 8px;
  border-radius: 4px;
  font-size: 0.9em;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #DADDE0;
  background-color: #F7F8FA;
  z-index: 999;

  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.2s;
}

[data-tooltip]:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type=number] {
  -moz-appearance: textfield;
}

a {
  cursor: pointer;
}

li.MuiListItem-root.MuiMenuItem-root {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

form {
  width: 100%;
}